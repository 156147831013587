<script>
  import { translateTitle } from '@/utils/i18n'
  import Post from '@/views/operate/customAdvertising/compenents/postCommon.vue'
  import { getAllClassificationPage } from '@/api/operate'
  import config from '../../config'
  export default {
    components: {
      Post,
    },
    data() {
      return {
        ...config,
        params: {
          pic: null,
          title: null,
          begin_time: null,
          end_time: null,
          link: null,
          place: null,
          position: 1,
          tag: null,
          is_show: 1,
          upload_type: 1,
          pic_url: null,
          ac_op: -1,
          show_label: -1,
          show_title: null,
          class_id: null,
          audit_config: [],
        },
      }
    },
    computed: {
      column() {
        return [
          {
            label: '是否显示',
            prop: 'is_show',
            type: 'radio',
            list: this.whetherList1,
          },
          {
            label: '广告备注',
            prop: 'title',
          },
          {
            label: '审核配置',
            prop: 'ac_op',
            type: 'radio',
            list: this.whetherList2,
          },
          {
            label: '配置项',
            prop: 'audit_config',
            type: 'checkbox',
            list: this.phoneConfigList,
            tips: '若选择审核版，则用户看不到配置的内容，若选择普通版，则审核人员看不到配置的内容',
            isHide: this.params.ac_op != 1,
          },
          {
            label: '显示标题',
            prop: 'show_title',
          },
          {
            label: '上传图片',
            prop: 'pic',
            type: 'uploadImage',
            width: 100,
            height: 100,
            eventName: 'handleImageUrl',
            tips: '建议尺寸280x280px',
          },
          {
            label: '跳转',
            prop: 'link',
            // tips: `${this.translateTitle(
            //   '选填，可以配置app内部任意页面的跳转链接。'
            // )}<a href="http://help_for_magapp.mydoc.io/?t=177452/" target="_blank">${this.translateTitle(
            //   '查看示例'
            // )}</a>`,
            tips: `${this.translateTitle(
              '选填，可以配置app内部任意页面的跳转链接。'
            )}`,
            // clickText: '选择跳转地址',
            // clickEventName: 'handleCheckLink',
          },
          {
            label: '广告分类',
            prop: 'class_id',
            type: 'select',
            optionLabel: 'name',
            list: this.advertisingTypeList,
          },
          {
            label: '开始时间',
            prop: 'begin_time',
            timeType: 'datetime',
            type: 'startDaterangePicker',
          },
          {
            label: '结束时间',
            prop: 'end_time',
            timeType: 'datetime',
            type: 'endDaterangePicker',
          },
        ]
      },
    },
    created() {
      this.getAllClassificationPage()
    },
    methods: {
      translateTitle,
      getAllClassificationPage() {
        getAllClassificationPage({}).then((res) => {
          this.advertisingTypeList = res.list || []
        })
      },
    },
  }
</script>
